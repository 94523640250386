import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
import HomepageTile from '../components/HomepageTile';
import Carousel from '../components/Carousel';
import ContactForm from '../components/ContactForm';
import { HomepageCallout } from 'gatsby-theme-carbon';
import { navigate } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <br />
    <Row mdxType="Row">
      <Column colLg={4} mdxType="Column">
  <HomepageTile //ratio="3:2"
        ratio={{
          sm: '3:2',
          md: '1:1',
          lg: '3:2'
        }} image="/images/entry.png" actionIcon="no-icon" mdxType="HomepageTile">
    <HomepageTile ratio={{
            sm: '3:2',
            md: '1:1',
            lg: '3:2'
          }} theme="dark" backgroundColor="#0000009f" subtitle="Test your strategies" title="Download Wizbot Strategy" className="bx--type-expressive-heading-05" actionIcon="article" link="/products/wizbot" mdxType="HomepageTile" />
    </HomepageTile>
      </Column>
      <Column colLg={4} mdxType="Column">
  <HomepageTile //ratio="3:2"
        ratio={{
          sm: '3:2',
          md: '1:1',
          lg: '3:2'
        }} image="/images/se-chart.png" actionIcon="no-icon" mdxType="HomepageTile">
    <HomepageTile ratio={{
            sm: '3:2',
            md: '1:1',
            lg: '3:2'
          }} theme="dark" backgroundColor="#0000009f" subtitle="Master your skill of seeing second entries" title="Download Second Entry Indicator" className="bx--type-expressive-heading-05" actionIcon="article" link="/products/second-entry" mdxType="HomepageTile" />
    </HomepageTile>
      </Column>
      <Column colLg={4} mdxType="Column">
  <HomepageTile //ratio="3:2"
        ratio={{
          sm: '3:2',
          md: '1:1',
          lg: '3:2'
        }} image="/images/whisperer-ct.png" actionIcon="no-icon" mdxType="HomepageTile">
    <HomepageTile ratio={{
            sm: '3:2',
            md: '1:1',
            lg: '3:2'
          }} theme="dark" backgroundColor="#0000009f" subtitle="Don't miss out on this versatile and essential tool for successful trading" title="Download ATM Whisperer" className="bx--type-expressive-heading-05" actionIcon="article" link="/products/atm-whisperer" mdxType="HomepageTile" />
    </HomepageTile>
      </Column>
      <Column colLg={4} mdxType="Column">
  <HomepageTile //ratio="3:2"
        marginTop="40px" ratio={{
          sm: '3:2',
          md: '1:1',
          lg: '3:2'
        }} image="/images/TickChartCompare.png" actionIcon="no-icon" mdxType="HomepageTile">
    <HomepageTile ratio={{
            sm: '3:2',
            md: '1:1',
            lg: '3:2'
          }} theme="dark" backgroundColor="#0000009f" subtitle="Trade MES closer to ES 2000 ticks" title="Download Tick Chart Compare Indicator" className="bx--type-expressive-heading-05" actionIcon="article" link="/products/sync-charts" mdxType="HomepageTile" />
    </HomepageTile>
      </Column>
      <Column colLg={4} mdxType="Column">
  <HomepageTile //ratio="3:2"
        marginTop="40px" ratio={{
          sm: '3:2',
          md: '1:1',
          lg: '3:2'
        }} image="/images/mic1.png" actionIcon="no-icon" mdxType="HomepageTile">
    <HomepageTile ratio={{
            sm: '3:2',
            md: '1:1',
            lg: '3:2'
          }} theme="dark" backgroundColor="#0000009f" title="NinjaTrader 8 Alert Sounds" subtitle="How to Change Annoying Buzzing Alerts to Nice Voiceover" className="bx--type-expressive-heading-05" actionIcon="article" link="/blog/better-sounds" mdxType="HomepageTile" />
    </HomepageTile>
      </Column>
    </Row>
    <Row mdxType="Row">
  <Column mdxType="Column">
        <hr></hr>
        <h3>{`Services`}</h3>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colLg={4} colMd={4} mdxType="Column">
    <HomepageTile marginTop="40px" hoverDark ratio="1:1" theme="dark" subtitle="Services" title="NinjaTrader Development" backgroundColor="#6e67ff" transparentImage="images/web.svg" //      identityIcon="images/apple.svg"
        actionIcon="article" link="#development" mdxType="HomepageTile" />
    <HomepageTile marginTop="40px" hoverDark ratio="1:1" theme="dark" subtitle="Services" title="Custom indicators" backgroundColor="#9267ff" transparentImage="images/indicator.svg" actionIcon="article" link="#indicators" mdxType="HomepageTile" />
  </Column>
  <Column colLg={4} colMd={4} mdxType="Column">
    <HomepageTile marginTop="40px" hoverDark ratio="1:1" theme="dark" subtitle="Services" title="Data analytics and visualization" backgroundColor="#6784ff" transparentImage="images/proto.svg" actionIcon="article" link="#analytics" mdxType="HomepageTile" />
    <HomepageTile marginTop="40px" hoverDark ratio="1:1" theme="dark" subtitle="Services" title="Portfolio management tools and strategy supervision tools" backgroundColor="#6e67ff" transparentImage="images/case.svg" actionIcon="article" link="#tools" mdxType="HomepageTile" />
  </Column>
  <Column colLg={4} colMd={4} mdxType="Column">
    <HomepageTile marginTop="40px" hoverDark ratio="1:1" theme="dark" subtitle="Services" title="Ecosystem Development" backgroundColor="#67a8ff" transparentImage="images/staff.svg" actionIcon="article" link="#ecosystem" mdxType="HomepageTile" />
    <HomepageTile marginTop="40px" hoverDark ratio="1:1" theme="dark" subtitle="Services" title="Consultation" backgroundColor="#6784ff" transparentImage="images/apps.svg" actionIcon="article" link="#consultation" mdxType="HomepageTile" />
  </Column>
    </Row>
    <Row mdxType="Row">
      <Column mdxType="Column">
        <hr></hr>
        <br /><br />
      </Column>
      <Column colMd={3} colLg={6} className="bx--type-expressive-heading-03" mdxType="Column">
        <p>{`Do you need technology or a new concept for the business? `}</p>
        <p>{`Contact us and we’ll help you plan your route. Our team will contact you for more information about your requirements and a detailed analysis of your needs. `}</p>
        <p>{`We will use your information to make a detailed evaluation proposal available to you.`}</p>
      </Column>
      <Column colMd={3} colLg={5} offsetMd={1} offsetLg={1} mdxType="Column">
Please fill out the form below and let us talk to you about your idea. You will be kept confidential with your details.
        <br />
        <ContactForm mdxType="ContactForm" />
      </Column>
    </Row>
    <br /><br />
    {
      /* <div style={{backgroundColor: '#343444', padding: '2rem', position: 'absolute', right: '0', left: '0'}}>
      </div> */
    }
    <div style={{
      backgroundColor: '#343444',
      padding: '2rem',
      margin: 'auto',
      paddingTop: '4rem'
    }}>
      <Row mdxType="Row">
        <Column colLg={4} mdxType="Column">
          <div style={{
            padding: '2rem',
            paddingTop: '0rem'
          }}>
            <img {...{
              "src": "/images/NinjaTrader_Logo.png",
              "alt": "Technologies"
            }}></img>
          </div>
        </Column>
        <Column colLg={4} mdxType="Column">
Our Indicators are built exclusively for the NinjaTrader Platform – Our #1 recommended trading platform
        </Column>
        <Column colLg={4} mdxType="Column">
          <div style={{
            float: 'right'
          }}>
            <button className="bx--btn bx--btn--primary" onClick={() => {
              navigate("/ninjatrader");
            }}>GET STARTED FOR FREE</button>
          </div>
        </Column>
      </Row>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      